import React from 'react'
import * as UI from '@/components/UI'
import { Wrapper, Inner, Title, Logo, ArrowLink } from './Sidebar.css'

const PainFinderIndexSidebar = ({ content, backLink = '/', ...props }) => (
  <Wrapper {...props}>
    <Inner>
      <div>
        <UI.Link to='/'>
          <Logo />
        </UI.Link>
        <Title>{content.sidebar_title}</Title>
      </div>
      <ArrowLink color='blue' to={backLink} reverse>
        {content.sidebar_back}
      </ArrowLink>
    </Inner>
  </Wrapper>
)

export default PainFinderIndexSidebar
