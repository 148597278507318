import styled from 'styled-components'
import above from '@/utils/above'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  transition: 0.3s opacity;
  pointer-events: none;
  position: fixed;
  background: ${p => p.theme.colors.snow};
  top: 0;
  left: 0;
  padding: 1rem;
  overflow: scroll;
  height: 100vh !important;
  ${above('lg')`
    z-index: 2;
    position: absolute;
    left: inherit;
    overflow: inherit;
    background: inherit;
    margin: -3rem;
    padding: 3rem;
    width: calc(100% + 6rem);
    overflow-y: scroll;
    overflow-x: visible;
    top: 3rem;
  `}
  ${p =>
    p.visible &&
    `
    pointer-events: all;
    opacity: 1;
  `}
`

export const Inner = styled.div`
  background: ${p => p.theme.colors.white};
  border-radius: 0.125rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0.25rem 2.5rem 0 rgba(6, 29, 51, 0.08);
`

export const Top = styled.div`
  padding: 1.5rem 1.25rem;
  background-color: #ffffff;
  ${above('sm')`
    padding: 1.5rem;
  `}
`

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CloseButton = styled(UI.Link)`
  display: block;
  padding: 1.25rem;
  margin: -1.25rem;
  transition: 0.2s all;
  ${above('lg')`
    color: ${p => p.theme.colors.gray};
  `}
  &:hover,
  &:focus,
  &:active {
    color: ${p => p.theme.colors.maastricht};
  }
`

export const Label = styled(UI.Label)`
  display: inline-block;
  font-weight: 500;
  border-radius: 0.125rem;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  background: ${p => p.theme.colors.gunmetal}33;
`

export const Title = styled(UI.Subtitle)`
  font-family: ${p => p.theme.type.fonts.headline};
  margin: 1rem 0;
`

export const Content = styled(UI.SmallText).attrs({ as: 'div' })`
  margin-bottom: 2rem;
  p + p {
    margin-top: 1rem;
  }
`

export const List = styled.ul`
  margin: 1.5rem 0 3rem;
`

export const Item = styled(UI.Footnote).attrs({ as: 'li' })`
  position: relative;
  display: flex;
  align-items: baseline;
  padding: 1rem 0 1.125rem 2.375rem;
  counter-reset: item;
  &:before {
    content: "${p => p.index}";
    ${getTypeStyle('label')};
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0.875rem;
    left: 0;
    min-width: 1.375rem;
    height: 1.375rem;
    background: ${p => p.theme.colors.flash};
  }
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${p => p.theme.colors.flash};
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
`

export const Bottom = styled.div`
  background-color: #ffffff;
`

export const BottomList = styled.div`
  padding: 0.5rem;
`

export const Separator = styled.div`
  height: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${p => p.theme.colors.gray};
  &:before {
    content: '';
    position: absolute;
    border-bottom: 1px dashed ${p => p.theme.colors.gray};
    height: 1px;
    left: 0;
    right: 0;
  }
  &:after {
    content: '';
    background: ${p => p.theme.colors.white};
    position: absolute;
    right: 1.375rem;
    width: 1.5rem;
    height: 1rem;
  }
  svg {
    position: relative;
    z-index: 1;
    margin-right: 1.25rem;
  }
`

export const BottomGroup = styled(UI.Group)`
  display: block;
`

export const Card = styled(UI.Link)`
  display: block;
  width: 100%;
  padding: 1.5rem 1.5rem 2rem;
  background: ${p => p.theme.colors[p.color || 'orange']};
  color: ${p => p.theme.colors.white};
  border-radius: 0.125rem;
  &:hover,
  &:focus,
  &:active {
    color: ${p => p.theme.colors.white};
  }
`

export const CardLabel = styled(UI.Label)`
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.8;
  margin-bottom: 0.25rem;
`

export const CardTitle = styled(UI.Caption)`
  font-family: ${p => p.theme.type.fonts.headline};
  max-width: 12em;
`
