import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { parse } from 'query-string'
import { graphql } from 'gatsby'
import Layout from '@/layout'
import flatMap from '@/utils/flatMap'
import Sidebar from '@/components/contents/PainFinderIndex/Sidebar'
import KindSelector from '@/components/contents/PainFinderIndex/KindSelector'
import ResultsPerKind from '@/components/contents/PainFinderIndex/ResultsPerKind'
import getMetaImage from '@/utils/getMetaImage'

const getBackLink = ({ slug, kind, result }) => {
  if (kind && result) return `/${slug}?kind=${kind}`
  if (kind) return `/${slug}`
  return '/'
}

const Relative = styled.div`
  position: relative;
  pointer-events: none;
`

const PainFinderIndexPage = ({ data, location }) => {
  const search = parse(location?.search)

  const [{ kind, result }, setParams] = useState({ kind: '', result: '' })

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setParams(search)
  }, [location?.search])
  /* eslint-enable react-hooks/exhaustive-deps */

  const content = { ...data?.content?.data, slug: data?.content?.slug }
  const serious_game = {
    ...data?.serious_game?.data,
    slug: data?.serious_game?.slug,
  }
  const course = { ...data?.course?.data, slug: data?.course?.slug }
  const contentTypes = { serious_game, course }

  const kinds =
    flatMap(content?.content_kinds, x => x?.kind?.document)?.map(x => ({
      ...x?.data,
      slug: x?.slug,
    })) || []

  const selectedKind = kinds.find(({ slug }) => kind === slug)

  const backLink = getBackLink({ slug: content.slug, kind, result })

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <Sidebar content={content} backLink={backLink} />
      <Relative>
        <KindSelector kinds={kinds} content={content} visible={!selectedKind} />
        <ResultsPerKind
          kind={selectedKind}
          resultSlug={result}
          content={content}
          visible={!!selectedKind}
          contentTypes={contentTypes}
          backLink={backLink}
        />
      </Relative>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PainFinderIndex($locale: String) {
    content: prismicPainFinderPage(lang: { eq: $locale }) {
      lang
      slug: uid
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        sidebar_title
        sidebar_back
        content_title
        content_diagnostic
        content_prescription
        content_kinds {
          kind {
            document {
              ... on PrismicPainFinderKind {
                slug: uid
                data {
                  meta_title
                  meta_description
                  meta_image {
                    url
                  }
                  content_label
                  content_title
                  content_description
                  content_list_title
                  content_pain_list {
                    pain {
                      document {
                        ... on PrismicPainFinder {
                          slug: uid
                          data {
                            sidebar_text
                            diagnostic_title
                            diagnostic_content {
                              html
                            }
                            prescription_list {
                              item
                            }
                            results {
                              result {
                                document {
                                  ... on PrismicPainFinderPage {
                                    type
                                    slug: uid
                                    data {
                                      meta_title
                                    }
                                  }
                                  ... on PrismicCourse {
                                    type
                                    slug: uid
                                    data {
                                      meta_title
                                    }
                                  }
                                  ... on PrismicCoursesPage {
                                    type
                                    slug: uid
                                    data {
                                      meta_title
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    serious_game: prismicSeriousGamesPage(lang: { eq: $locale }) {
      slug: uid
      data {
        title: meta_title
      }
    }
    course: prismicCoursesPage(lang: { eq: $locale }) {
      slug: uid
      data {
        title: meta_title
      }
    }
  }
`

export default PainFinderIndexPage
