import styled, { css } from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Wrapper = styled.div`
  transition: 0.3s opacity, 0.3s transform, 0s position 0.6s;
  top: 0;
  width: 100%;
  height: 0;
  position: absolute;
  opacity: 0;
  transform: translateX(-1rem);
  pointer-events: none;
  ${above('lg')`
    transform: translateX(-2rem);
  `}
  ${p =>
    p.visible &&
    css`
      position: relative;
      height: auto;
      opacity: 1;
      transform: none;
      pointer-events: all;
      ${above('lg')`
        transform: none;
      `}
    `}
`

export const Inner = styled(UI.Container)`
  padding-top: 0.5rem;
  padding-bottom: 2.5rem;
  position: relative;
  ${above('lg')`
    padding-top: 7.75rem;
    padding-bottom: 4rem;
  `}
`

export const Cell = styled.div`
  pointer-events: ${p => (p.visible ? 'all' : 'none')};
  width: ${getColumnSize(12)};
  padding-bottom: 4rem;
  position: relative;
  z-index: 20;
  ${above('lg')`
    margin-left: ${getColumnSize(3)};
    width: ${getColumnSize(8)};
  `}
  ${above('xg')`
    margin-left: ${getColumnSize(2)};
    width: ${getColumnSize(8)};
  `}
`

export const Title = styled(UI.SectionTitle)`
  margin-bottom: 5rem;
  max-width: 10em;
  ${above('lg')`
    margin-bottom: 4rem;
  `}
`

export const CardCell = styled.div`
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(6)};
  `}
`

export const Card = styled(UI.Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  color: ${p => p.theme.colors.maastricht};
  background: ${p => p.theme.colors.white};
  box-shadow: 0 0.25rem 2.5rem 0 rgba(6, 29, 51, 0.08);
  border-radius: 0.125rem;
  transition: all ${p => p.theme.transitions.ease()};
  &:hover,
  &:focus,
  &:active {
    color: ${p => p.theme.colors.white};
    background: ${p => p.theme.colors.orange};
  }
  ${above('lg')`
    min-height: 19rem;
  `}
`

export const CardTop = styled.div`
  margin-bottom: 5rem;
`

export const CardLabel = styled(UI.Label)`
  color: ${p => p.theme.colors.gray};
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0.25rem;
  ${Card}:hover &,
  ${Card}:focus &,
  ${Card}:active & {
    color: inherit;
    opacity: 0.8;
  }
`

export const CardTitle = styled(UI.Subtitle)`
  font-family: ${p => p.theme.type.fonts.headline};
`

export const CardDescription = styled(UI.SmallText)``
