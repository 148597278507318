import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import Skeleton from './Skeleton'
import Result from './Result'
import {
  Wrapper,
  Inner,
  Cell,
  Grid,
  Left,
  Title,
  Right,
  Relative,
  ItemLink,
} from './ResultsPerKind.css'

const PainFinderIndexResultsPerKind = ({
  kinds,
  kind,
  resultSlug,
  content,
  visible,
  contentTypes,
  backLink,
  ...props
}) => {
  const list =
    flatMap(kind?.content_pain_list, x => x?.pain?.document)?.map(x => ({
      ...x?.data,
      slug: x?.slug,
    })) || []

  const selected = list?.find(({ slug }) => resultSlug === slug)

  return (
    <>
      <Wrapper visible={visible} {...props}>
        <Inner>
          <UI.Grid>
            <Cell visible={visible}>
              <Grid>
                <Left>
                  <Title>{kind?.content_list_title}</Title>
                  {list.map(item => (
                    <ItemLink
                      to={`/${content.slug}?kind=${kind.slug}&result=${item.slug}`}
                      current={resultSlug === item.slug}
                      key={item.slug}
                    >
                      {item.sidebar_text}
                    </ItemLink>
                  ))}
                </Left>
              </Grid>
            </Cell>
          </UI.Grid>
        </Inner>
      </Wrapper>
      <UI.Portal>
        <Wrapper visible={visible} {...props} right>
          <Inner>
            <UI.Grid>
              <Cell right>
                <Grid>
                  <Right>
                    <Relative>
                      <Skeleton visible={!selected} />
                      <Result
                        visible={!!selected}
                        selected={selected}
                        content={content}
                        contentTypes={contentTypes}
                        backLink={backLink}
                      />
                    </Relative>
                  </Right>
                </Grid>
              </Cell>
            </UI.Grid>
          </Inner>
        </Wrapper>
      </UI.Portal>
    </>
  )
}

export default PainFinderIndexResultsPerKind
