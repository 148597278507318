import styled, { css } from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  transition: 0.3s opacity 0.3s, 0.3s transform 0.3s, 0s position 0.6s;
  ${p =>
    !p.visible &&
    css`
      transition: 0.3s opacity, 0.3s transform, 0s position 0.6s;
      width: 100%;
      opacity: 0;
      transform: translateX(-1rem);
      ${above('lg')`
        transform: translateX(-2rem);
      `}
    `}
  ${p =>
    p.right &&
    `
    pointer-events: none;
    position: fixed;
    width: 100%;
  `}
`

export const Inner = styled(UI.Container)`
  position: relative;
`

export const Cell = styled.div`
  pointer-events: ${p => (p.visible ? 'all' : 'none')};
  width: ${getColumnSize(12)};
  ${above('lg')`
    width: ${getColumnSize(10)};
    margin-left: ${getColumnSize(2)};
  `}
  ${above(1500)`
    width: ${getColumnSize(11)};
    margin-left: ${getColumnSize(1)};
  `}
  ${p => p.right && `pointer-events: none;`}
`

export const Grid = styled(UI.Grid)`
  align-items: stretch;
`

export const Left = styled.div`
  width: ${getColumnSize(12)};
  padding-top: 0.5rem;
  padding-bottom: 2.5rem;
  ${above('lg')`
    width: ${getColumnSize((5 * 12) / 10)};
    padding-top: 7.75rem;
    padding-bottom: 4rem;
  `}
  ${above('xg')`
    width: ${getColumnSize((4 * 12) / 10)};
  `}
  ${above(1500)`
    width: ${getColumnSize((4.5 * 12) / 11)};
  `}
`

export const ItemLink = styled(UI.Link)`
  ${getTypeStyle('smallText')};
  display: block;
  background: ${p => p.theme.colors.white};
  color: ${p => p.theme.colors.maastricht}CC;
  border-radius: 0.125rem;
  box-shadow: 0 0.25rem 2.5rem 0 rgba(6, 29, 51, 0.08);
  overflow: hidden;
  position: relative;
  transition: 0.2s all;
  margin: 1.5rem 0;
  padding: 1.5rem;
  ${above('lg')`
    box-shadow: none;
    background: ${p => p.theme.colors.flash}B2;
    color: ${p => p.theme.colors.maastricht}CC;
  `}
  ${above('xg')`
    margin: 1.5rem -1.5rem;
  `}
  &:hover, &:focus, &:active {
    box-shadow: 0 0.25rem 2.5rem 0 rgba(6, 29, 51, 0.08);
    background: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.maastricht};
  }
  &:before {
    transition: 0.2s all;
    content: '';
    display: block;
    position: absolute;
    transform: translateX(-0.125rem);
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.125rem;
    border-radius: 0.125rem;
    background: ${p => p.theme.colors.orange};
  }
  ${p =>
    p.current &&
    css`
      ${above('lg')`
      box-shadow: 0 0.25rem 2.5rem 0 rgba(6, 29, 51, 0.08);
      background: ${p.theme.colors.white};
      color: ${p.theme.colors.maastricht};
      &:before {
        transform: translateX(0);
        opacity: 1;
      }
    `}
    `}
`

export const Right = styled.div`
  pointer-events: none;
  width: ${getColumnSize(12)};
  min-height: 100vh;
  top: 0;
  ${above('lg')`
    width: ${getColumnSize((5 * 12) / 10)};
    margin-left: 50%;
  `}
  ${above(1500)`
    width: ${getColumnSize((5.5 * 12) / 11)};
  `}
`

export const Relative = styled.div`
  position: relative;
  z-index: 1;
`

export const Title = styled(UI.SectionTitle)`
  margin-bottom: 5rem;
  max-width: 10em;

  ${above('lg')`
  font-size: 40px;
    margin-bottom: 4rem;
  `}
`
