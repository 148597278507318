import styled from 'styled-components'
import above from '@/utils/above'
import * as UI from '@/components/UI'

export const Wrapper = styled.div`
  ${above('lg')`
    background: ${p => p.theme.colors.flash};
    display: flex;
    flex-direction: column;
    position: fixed;
    min-height: 100%;
    z-index: 20;
  `}
`

export const Inner = styled(UI.Container)`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  ${above('lg')`
    padding: 2.75rem 3.5rem 2.5rem 2.5rem;
    flex: 1;
    flex-direction: column;
  `}
`

export const Title = styled(UI.Label)`
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
  color: ${p => p.theme.colors.gray};
  ${above('lg')`
    margin-top: 0.75rem;
  `}
`

export const Logo = styled(UI.Logo)`
  height: 0.75rem;
  width: auto;
  ${above('lg')`
    height: 1rem;
  `}
`

export const ArrowLink = styled(UI.ArrowLink)`
  svg {
    display: none;
    ${above('lg')`
      display: block;
    `}
  }
`
