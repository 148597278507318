import React from 'react'
import * as UI from '@/components/UI'
import srcCheeto from '@/assets/images/nobox-yellow.png'
import srcMoon from '@/assets/images/nobox-green.png'
import {
  Wrapper,
  Inner,
  Title,
  Cell,
  CardCell,
  Card,
  CardTop,
  CardLabel,
  CardTitle,
  CardDescription,
} from './KindSelector.css'

const PainFinderIndexKindSelector = ({ kinds, content, visible, ...props }) => {
  return (
    <Wrapper visible={visible} {...props}>
      <Inner>
        <UI.Parallax bottom='30%' left='40px' force={1.3} offsetComp={30}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={srcMoon}
              alt={content.content_title}
              width='280'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <UI.Parallax top='0%' right='25%' force={1.1} offsetComp={10}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0) rotate(45deg)`,
              }}
              src={srcCheeto}
              alt={content.content_title}
              width='400'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <UI.Grid>
          <Cell visible={visible}>
            <Title>{content.content_title}</Title>
            <UI.Grid vertical-gutter>
              {kinds.map(kind => (
                <CardCell key={kind.slug}>
                  <Card to={`/${content.slug}?kind=${kind.slug}`}>
                    <CardTop>
                      <CardLabel>{kind.content_label}</CardLabel>
                      <CardTitle>{kind.content_title}</CardTitle>
                    </CardTop>
                    <CardDescription>
                      {kind.content_description}
                    </CardDescription>
                  </Card>
                </CardCell>
              ))}
            </UI.Grid>
          </Cell>
        </UI.Grid>
      </Inner>
    </Wrapper>
  )
}

export default PainFinderIndexKindSelector
