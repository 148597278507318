import React from 'react'
import flatMap from '@/utils/flatMap'
import * as UI from '@/components/UI'
import {
  Wrapper,
  Inner,
  Top,
  TopBar,
  CloseButton,
  Label,
  Title,
  Content,
  List,
  Item,
  Bottom,
  Separator,
  BottomList,
  BottomGroup,
  Card,
  CardLabel,
  CardTitle,
} from './Result.css'

const getColor = type => {
  if (type === 'course') return 'orange'
  if (type === 'serious_game') return 'blue'
  return 'maastricht'
}

const Result = ({ selected, visible, content, contentTypes, backLink }) => {
  const list =
    flatMap(selected?.results, x => x?.result?.document)?.map(x => {
      return {
        ...x?.data,
        slug: x?.slug,
        type: x?.type,
      }
    }) || []

  return (
    <Wrapper visible={visible}>
      <Inner>
        <Top>
          <TopBar>
            <Label>{content?.content_diagnostic}</Label>
            <CloseButton to={backLink}>
              <UI.Icon.Close />
            </CloseButton>
          </TopBar>
          <Title>{selected?.diagnostic_title}</Title>
          <Content
            dangerouslySetInnerHTML={{
              __html: selected?.diagnostic_content?.html,
            }}
          />
          <Label>{content?.content_prescription}</Label>
          <List>
            {selected?.prescription_list?.map(({ item }, index) => (
              <Item key={item} index={index + 1}>
                {item}
              </Item>
            ))}
          </List>
        </Top>
        <Bottom>
          <Separator>
            <UI.Icon.Scissors />
          </Separator>
          <BottomList>
            <BottomGroup gutter={8} vertical-gutter>
              {list.map(item => {
                return (
                  <Card
                    to={`/${contentTypes?.[item?.type]?.slug}/${item.slug}`}
                    color={getColor(item.type)}
                    key={item.meta_title}
                  >
                    <CardLabel>{contentTypes?.[item?.type]?.title}</CardLabel>
                    <CardTitle>{item.meta_title}</CardTitle>
                  </Card>
                )
              })}
            </BottomGroup>
          </BottomList>
        </Bottom>
      </Inner>
    </Wrapper>
  )
}

export default Result
