import React from 'react'
import {
  Wrapper,
  Border,
  Top,
  Bottom,
  Label,
  LabelBottom,
  Title,
  Content,
  ContentBottom,
  Item,
} from './Skeleton.css'

const Skeleton = props => (
  <Wrapper {...props}>
    <Border>
      <Top>
        <Label />
        <Title />
        <Content />
        <Label />
        <Item />
        <Item />
        <Item />
      </Top>
      <Bottom>
        <LabelBottom />
        <ContentBottom />
      </Bottom>
    </Border>
  </Wrapper>
)

export default Skeleton
