import styled from 'styled-components'
import above from '@/utils/above'

export const Wrapper = styled.div`
  padding: 2.5rem 0;
  min-height: 100vh;
  display: none;
  flex-direction: column;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: 0 opacity 0.5s;
  ${above('lg')`
    display: flex;
    max-height: 100vh;
    margin: -3rem;
    padding: 3rem;
    width: calc(100% + 6rem);
    overflow-y: scroll;
    overflow-x: visible;
    top: 3rem;
  `}
  ${p =>
    p.visible &&
    `
    opacity: 1;
    transition: 0s opacity 0s;
  `}
`

export const Border = styled.div`
  border-radius: 0.25rem;
  flex-grow: 1;
  border: 1px dashed ${p => p.theme.colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Top = styled.div`
  padding: 2rem 1.5rem;
`

export const Bottom = styled.div`
  padding: 2rem 1.5rem;
  border-top: 1px dashed ${p => p.theme.colors.gray};
`

export const Label = styled.div`
  border: 1px dashed ${p => p.theme.colors.gray};
  height: 1rem;
  max-width: 5rem;
  width: 100%;
  margin-bottom: 2rem;
`

export const LabelBottom = styled.div`
  border: 1px dashed ${p => p.theme.colors.gray};
  height: 1rem;
  max-width: 5rem;
  width: 100%;
  margin-bottom: 1.5rem;
`

export const Title = styled.div`
  border: 1px dashed ${p => p.theme.colors.gray};
  height: 1.75rem;
  max-width: 20rem;
  width: 100%;
  margin-bottom: 1.25rem;
`

export const Content = styled.div`
  border: 1px dashed ${p => p.theme.colors.gray};
  height: 5rem;
  width: 100%;
  margin-bottom: 4.5rem;
`

export const ContentBottom = styled.div`
  border: 1px dashed ${p => p.theme.colors.gray};
  height: 4rem;
  width: 100%;
`

export const Item = styled.div`
  border: 1px dashed ${p => p.theme.colors.gray};
  height: 1.25rem;
  width: calc(100% - 40px);
  max-width: 20rem;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  position: relative;
  left: 2.5rem;
  &:before {
    border: 1px dashed ${p => p.theme.colors.gray};
    top: 0;
    margin-left: -2.5rem;
    content: '';
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1.25rem;
  }
`
